.header {
	&__aside {
		@include min(1024px) {
			padding: 1em 0;
			justify-content: center;
		}
	}
}

.blender__container {
	padding-bottom: 328px;
}

.main-wrapper__current_node {
	display: none;
}

.main-wrapper.centered.-padded {
	padding-bottom: 4vw;

	&:not(.-nosidebar) {
		@include min(1280px) {
			position: relative;
			max-width: 1650px;
			padding-left: 16vw;
			margin-left: 0;

			.main-wrapper__current_node {
				display: block;
				position: absolute;
				top: 0;
				bottom: 4vw;
				left: 0;
				width: 12vw;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: $main-aside-bg-color;

				.main-wrapper__current_node__title {
					transform: rotate(-90deg);
					white-space: nowrap;
					color: $main-aside-color;
				}
			}
		}
	}
}

@include max(1023px) {
	.header__aside {
		display: none;
	}
}
