
.tiles {
    &__list{
        display: flex;
        flex-wrap: wrap;
        padding: 0 0 1rem;
        margin: 0 -.5rem;
        margin-top: 2.95em;
        list-style-type: none;
    }
}

.tile{
    &__item {
        display: flex;
        align-items: center;
        width: 100%;
		font-size: rem(26px);
		font-family: $font-headings;
		font-weight: 600;
        padding: 0 .5rem 1rem;
        @include min(500px){
            width: 50%;
        }
        @include min(1024px){
            width: 33.3333333%;
            .-nosidebar &{
                width: 25%;   
            }
        }
	}
    

	&__link {
        position: relative;
        padding: 1.25rem .5rem;
        display: block;
        text-align: center;
        flex-grow: 1;

		@include link(&) {
            color: $main-nav-button-color-hover;
            background-color: $main-nav-button-bg-hover;
			text-decoration: none;
		}

		@include link-over(&) {
            background-color: $main-nav-button-bg;
            color: $main-nav-color;
		}
	}
}