//styly pro Masonry layout
$spacing: $grid-spacing*2;

.masonry {
	list-style-type: none;
	padding: 0;
	margin: 0;

	@include bp("> 450px") {
		column-count: 2;
		column-gap: $spacing;
	}

	@include bp("> 1024px") {
		column-count: 3;
		column-gap: $spacing*.75;
	}

	&__item {
		-webkit-column-break-inside: avoid;
		break-inside: avoid;
		page-break-inside: avoid;
		position: relative;
		margin-bottom: 0;

		@include bp("> 450px") {
			margin-bottom: $spacing;
		}

		@include bp("> 1024px") {
			margin-bottom: $spacing*.75;
		}
	}
}
